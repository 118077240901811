@import url('https://fonts.googleapis.com/css2?family=Dongle:wght@300;400;700&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500;600;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee+Hairline&display=swap');

/* @import url('https://fonts.googleapis.com/css2?family=Titan+One&display=swap'); */

@font-face {
    font-family: 'yeye';
    src: url('../Assets/Fonts/yeye.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Bubblegum';
    src: url('../Assets/Fonts/Bubblegum.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

:root {
    /* --tile_font_family: 'Titan One', cursive; */
    --tile_font_family: "yeye", 'Arial Black', sans-serif; 
    /* --tile_font_family: "Bubblegum", 'Arial Black', sans-serif; */
    --main_font_family: 'Fredoka', sans-serif;
    --robot_font_family: 'Bungee Hairline', cursive;
    --title_font_size: 35px;
    --sub_title_font_size: 25px;
}

@media (max-width: 420px) {
    :root {
        --sub_title_font_size: 20px;
    }
}

@media (max-width: 340px) {
    :root {
        --title_font_size: 30px;
    }

    html, body {
        font-size: 16px;
    }
    
}