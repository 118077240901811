

.calclulator_input_container {
    position: relative;
    margin-left: calc(50% - 123px);
    margin-top: 20px;
    width: 240px;
    height: 50px;
    border: 3px solid var(--charcol_gray);
    border-radius: 15px;
    background-color: var(--light_blue);
    overflow: hidden;
}

.calculator_input_label {
    position: absolute;
    left: 20px;
    top: 0px;
    height: 50px;
    line-height: 50px;
}

.calculator_input_input {
    position: absolute;
    right: 20px;
    width: 130px;
    height: 50px;
    line-height: 50px;
    border: none;
    font-size: 20px;
    text-align: right;
    background-color: transparent;
}

.calculator_input_input:focus {
    outline: none;
}

.calculator_value_converted {
    position: relative;
    margin-left: calc(50% - 153px);
    margin-top: 20px;
    width: 300px;
    height: 80px;
    border: 3px solid var(--charcol_gray);
    border-radius: 25px;
    line-height: 80px;
    text-align: center;
    font-size: var(--title_font_size);
    background-color: var(--light_blue);
}

.coin_overlay_0 {
    position: absolute;
    bottom: 0px;
    right: -20px;
    width: 50px;
    object-fit: contain;
    transform: rotate(50deg);
}

.coin_overlay_1 {
    position: absolute;
    top: -20px;
    right: -35px;
    width: 50px;
    object-fit: contain;
    transform: rotate(5deg);
}

.coin_overlay_2 {
    position: absolute;
    bottom: 10px;
    right: -70px;
    width: 50px;
    object-fit: contain;
    transform: rotate(75deg);
}

.coin_overlay_3 {
    position: absolute;
    bottom: 0px;
    left: -40px;
    width: 50px;
    object-fit: contain;
    transform: rotate(-10deg);
}

.coin_overlay_4 {
    position: absolute;
    top: -20px;
    left: -10px;
    width: 50px;
    object-fit: contain;
    transform: rotate(60deg);
}

.coin_overlay_5 {
    position: absolute;
    top: -0px;
    left: -70px;
    width: 50px;
    object-fit: contain;
    transform: rotate(20deg);
}


.calculator_slider_container {
    position: relative;
    width: 100%;
    height: 300px;
    margin-top: 40px;
    overflow: hidden;
}

.calculator_bar_full {
    position: absolute;
    left: 45px;
    top: 139px;
    height: 20px;
    width: calc(100% - 90px);
    border: 1px solid var(--charcol_gray);
    background-color: var(--light_green);
}

.calculator_bar_selected {
    position: absolute;
    left: 45px;
    top: 140px;
    height: 20px;
    width: 50px;
    background-color: var(--medium_green);
}

.globe_slider {
    position: absolute;
    top: 125px;
    left: 0px;
    height: 50px;
}

.moon_slider {
    position: absolute;
    top: 125px;
    right: 0px;
    height: 50px;
}

.rocket_slider_container {
    position: absolute;
    top: 50px;
    left: -50px;
    height: 200px;
    width: 200px;

    cursor: pointer;
}

.rocket_slider {
    position: absolute;
    top: 75px;
    left: 50px;

    height: 50px;
    width: 100px;
    object-fit: contain;

    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    cursor: pointer;
}

.hand_slider {
    position: absolute;
    top: 105px;
    left: 100px;
    height: 50px;
    width: 50px;
    object-fit: contain;
    animation: 2s infinite linear animate_hand;
    opacity: 0.5;
}

.rocket_copy {
    opacity: 0.5;
    animation: 2s infinite linear animate_rocket;
}

@keyframes animate_hand {
    0% { transform: rotate(-10deg); left: 90px; }
    50% { transform: rotate(20deg); left: 220px; }
    100% { transform: rotate(-10deg); left: 90px; }
}

@keyframes animate_rocket {
    0% { left: 50px; }
    50% { left: 160px; }
    100% { left: 50px; }
}

.top_slider_indicator_current {
    position: absolute;
    left: 100px;
    width: 20px;
    top: 70px;
    transform: rotate(180deg);
}

.bottom_slider_indicator_start {
    position: absolute;
    left: 46px;
    width: 20px;
    bottom: 70px;
}

.bottom_slider_indicator_launch {
    position: absolute;
    left: calc(50% - 3px);
    width: 20px;
    bottom: 70px;
}

.bottom_slider_indicator_moon {
    position: absolute;
    right: 34px;
    width: 20px;
    bottom: 70px;
}

.indicator_container_start {
    position: absolute;
    left: 20px;
    width: 76px;
    height: 60px;
    background-color: white;
    bottom: 0px;
}

.indicator_container_launch {
    position: absolute;
    left: calc(50% - 33px);
    width: 80px;
    height: 60px;
    background-color: white;
    bottom: 0px;
}

.indicator_container_moon {
    position: absolute;
    right: 4px;
    width: 80px;
    height: 60px;
    background-color: white;
    bottom: 0px;
}

.indicator_container_current {
    position: absolute;
    left: 70px;
    width: 80px;
    height: 60px;
    background-color: white;
    top: 0px;
}

.price_indicator_inner {
    width: 100%;
    height: 20px;
    line-height: 20px;
    text-align: center;
}


.price_conversion_indicator_inner {
    width: 100%;
    height: 15px;
    line-height: 15px;
    text-align: center;
    font-size: 12px;
    margin-bottom: 5px;
}

.stage_indicator_inner {
    width: 100%;
    height: 20px;
    line-height: 20px;
    background-color: var(--light_gray);
    text-align: center;
    border-radius: 20px;
    font-size: 12px;
}

@media (max-width: 380px) {

    .calclulator_input_container {
        margin-left: 0px;
        width: calc(100% - 5px);
    }

    .calculator_value_converted {
        margin-left: 0px;
        width: calc(100% - 5px);
    }    
}