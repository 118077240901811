
.table_container {
    position: relative;
    width: 100%;
    /* overflow: scroll; */
}

.table_overflow_container {
    position: relative;
    width: 100%;
    overflow: scroll;
}

.row_item {
    position: relative;
    width: 100%;
    min-width: 1070px;
    height: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    /* margin-bottom: 20px; */
    cursor: pointer;
    /* background-color: red; */
    /* border-radius: 50px; */
    border-bottom: 1px solid var(--medium_gray);
    -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;
}

.row_item_first {
    border-top: 1px solid var(--medium_gray);
}

.description_row {
    display: inline-block;
    position: relative;
    width: 100%;
    min-width: 1070px;
    height: 20px;
    line-height: 20px;
    margin-bottom: 10px;
    font-size: 14px;
    margin-bottom: 30px;
}

.ranking_title {
    margin-top: 0px;
}


.row_contract_name {
    display: inline-block;
    margin-left: 20px;
    width: calc(40% - 140px);
    min-width: 160px;
    line-height: 50px;
    vertical-align: top;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.row_amount_staked {
    display: inline-block;
    margin-left: 10px;
    width: calc(20% - 16px);
    min-width: 130px;
    line-height: 50px;
    vertical-align: top;
    text-align: center;
}

.row_name_indicator {
    display: inline-block;
    margin-left: 20px;
    width: calc(40% - 70px);
    min-width: 230px;
    line-height: 50px;
    vertical-align: top;
}

.staking_disclaimer_font {
    font-size: 12px;
}