:root {
    --extra_small_margin: 10px;
    --small_margin: 20px;
    --medium_margin: 40px;
    --large_margin: 60px;
    --extra_large_margin: 80px;

    --medium_border_radius: 15px;
    --old_border_radius: 25px;

    --micro_shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    --small_shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    --medium_shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);

    --down_shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
}