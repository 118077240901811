.overlay_container {
    position: fixed;
    top: -1px;
    left: 0px;
    width: 100%;
    height: calc(100% + 2px);
    background-color: rgba(0,0,0,0.75);
    display: none;
    /* z-index: 100000000000; */
}

.pop_up_close_outter {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.pop_up_container {
    position: absolute;
    top: 20px;
    border-radius: 25px;
    left: calc(50% - 301px);
    width: 600px;
    height: calc(100% - 42px);
    border: 1px solid var(--charcol_gray);
    background-color: white;
    box-shadow: var(--small_shadow);
    overflow: hidden;
}

.pop_up_container_small {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 350px;
    height: auto;
}

.pop_up_inner {
    position: absolute;
    top: 51px;
    width: 100%;
    height: calc(100% - 51px);
    overflow: scroll;
}

.iframe_inner_pop_up {
    position: absolute;
    top: 51px;
    width: 100%;
    height: calc(100% - 51px);
    overflow: hidden;
}

.pop_up_title {
    position: fixed;
    width: 600px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-bottom: 1px solid var(--medium_gray);
}

.pop_up_title_simple {
    position: absolute;
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-bottom: 1px solid var(--medium_gray);
}


.pop_up_close {
    position: fixed;
    top: 35px;
    left: calc(50% - 280px);
    width: 15px;
    height: 15px;
    padding: 5px;
    background-color: var(--light_red);
    border-radius: 30px;
    cursor: pointer;
    z-index: 1;
    pointer-events: auto;
    -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;
}

.pop_up_close_small {
    top: calc(50% - 135px);
}

.pop_up_close_simple {
    position: absolute;
    top: 14px;
    left: 15px;
    width: 15px;
    height: 15px;
    padding: 5px;
    background-color: var(--light_red);
    border-radius: 30px;
    cursor: pointer;
    z-index: 1;
    pointer-events: auto;
    -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;
}

.pop_up_icon_large {
    margin-top: 70px;
    width: 200px;
    margin-left: calc(50% - 100px);
}



.pop_up_icon {
    margin-top: 50px;
    width: 100px;
    margin-left: calc(50% - 50px);
}

.pop_up_track_icon {
    margin-top: 70px;
    width: 100px;
    margin-left: calc(50% - 50px);
}

.pop_up_icon_track_spin {
    position: absolute;
    top: 70px;
    width: 100px;
    left: calc(50% - 50px);
    animation: infinite_spin 2s linear infinite;
}

.warning_title {
    margin-top: 20px;
    margin-left: 20px;
    width: calc(100% - 40px);
    font-size: var(--sub_title_font_size);
    text-align: center;
}

.warning_text {
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
    width: calc(100% - 40px);
    text-align: center;
}

.confirm_button {
    /* border-radius: 50px; */
    border-radius: var(--medium_border_radius);
    margin-left: 20px;
    margin-top: 20px;
    width: calc(100% - 50px);
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: var(--title_blue);
    border: 5px solid var(--outline_blue);
    cursor: pointer;
    -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;
}

.disagree_button {
    /* border-radius: 50px; */
    border-radius: var(--medium_border_radius);
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: calc(100% - 40px);
    height: 50px;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
    -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;
}



@media (max-width: 620px) {

    .pop_up_close {
        top: 25px;
        left: 30px;
    }

    .pop_up_title {
        width: calc(100% - 21px);
    }

    .pop_up_container {
        top: 10px;
        height: calc(100% - 23px);
        width: calc(100% - 21px);
        left: 10px;
    }

    .pop_up_container_small {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 350px;
        height: auto;
    }
}