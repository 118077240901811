:root {
    --white: #fff;

    /* Generally used for backgrounds */
    --light_blue: #d7f4fb; /*d7f4fb*/
    --medium_blue: #daf1f8;
    --light_orange: #fad7a7; /*fad7a7*/
    --light_purple: #ded0e4;
    --light_pink: #ffdbe0;
    --light_gray: #efeff3;
    --medium_gray: #d7d6e1;
    
    --vlight_green: #e0ffe9;
    --light_green: #d2e2cb;
    --medium_green: #4ccb70;
    --light_red: #ff9d9d;
    --s_light_red: #fbb8b8;
    --v_light_red: #ffd8d8;
    --dark_red: red;
    --medium_red: rgb(255, 72, 72);

    /* Generally used for text */
    --charcol_gray: #4e4e4e;
    --baby_blue: #73adf7;
    --funky_purple: #c663eb;

    --dark_blue: #338afc;

    /**/
    --title_orange: rgb(255, 222, 145);
    --title_purple: #ebbcff;
    
    --outline_blue: #d7f7ff;
    --title_blue: #acf0ff; /*baf1ff*/
    --selected_blue: #338afc;

    --mid_gray: #e8e7f0;
    --selected_gray: #e8e7f0;

    --title_pink: #fbc4f9;
    --outline_green: #d9ffe2;
    --title_green: #a9f0b9;
    --dark_green: #79fc98;
}

/*
:root {
    --white: #fff;

    --light_blue: #d7f4fb;
    --medium_blue: #daf1f8;
    --light_orange: #fad7a7;
    --light_purple: #ded0e4;
    --light_pink: #ffdbe0;
    --light_gray: #efeff3;
    --medium_gray: #d7d6e1;
    
    --vlight_green: #e0ffe9;
    --light_green: #d2e2cb;
    --medium_green: #4ccb70;
    --light_red: #ff9d9d;
    --s_light_red: #fbb8b8;
    --v_light_red: #ffd8d8;
    --dark_red: red;
    --medium_red: rgb(255, 72, 72);

    --charcol_gray: #4e4e4e;
    --baby_blue: #73adf7;
    --funky_purple: #c663eb;

    --dark_blue: #338afc;


    --title_orange: rgb(255, 222, 145);
    --title_purple: #ebbcff;
    --outline_blue: #d7f7ff;
    --title_blue: #baf1ff;
    --selected_blue: #338afc;

    --mid_gray: #e8e7f0;
    --selected_gray: #e8e7f0;

    --title_pink: #fbc4f9;
    --outline_green: #d9ffe2;
    --title_green: #a9f0b9;
    --dark_green: #79fc98;
}
*/