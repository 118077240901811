
.carousel_super_container {
    position: relative;
    height: 550px;
    overflow: hidden;
}

.carousel_container {
    position: relative;
    margin-top: 40px;
    width: 100%;
    height: 600px;
    overflow: scroll;
    pointer-events: none;
}

.carousel_container * {
    pointer-events: auto;
}

.fade_carousel_left {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 50px;
    height: 100%;
    background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
}

.fade_carousel_right {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 50px;
    height: 100%;
    background: linear-gradient(to left, #fff, rgba(255, 255, 255, 0));
}

.carousel_go_decrement  {
    position: absolute;
    top: calc(50% - 50px);
    left: 2px;
    width: 50px;
    height: 50px;
    border: 1px solid var(--medium_gray);
    background-color: white;
    border-radius: 50px;
    line-height: 50px;
    text-align: center;
    font-size: var(--sub_title_font_size);
    box-shadow: var(--small_shadow);
    cursor: pointer;
}

.carousel_go_increment  {
    position: absolute;
    right: 2px;
    top: calc(50% - 50px);
    width: 50px;
    height: 50px;
    border: 1px solid var(--medium_gray);
    background-color: white;
    border-radius: 50px;
    line-height: 50px;
    text-align: center;
    font-size: var(--sub_title_font_size);
    box-shadow: var(--small_shadow);
    cursor: pointer;
}



.carousel_inner_max {
    width: 2510px;
    height: 502px;
}

.empty_carousel_item {
    display: inline-block;
    width: 260px;
    height: 500px;
    margin-right: 20px;
}

.carousel_item {
    position: relative;
    display: inline-block;
    margin-top: 0px;
    width: 260px;
    height: 500px;
    background-color: white;
    border: 1px solid var(--medium_gray);
    margin-right: 20px;
    border-radius: 25px;
    overflow: hidden;
    /* box-shadow: var(--medium_shadow); */
    box-shadow: var(--small_shadow);
    cursor: pointer;
}

.carousel_title {
    position: absolute;
    top: 25px;
    left: 0px;
    height: 50px;
    width: 100%;
    text-align: center;
    font-family: var(--tile_font_family);
    /* font-size: var(--sub_title_font_size); */
}

.carousel_title_high {
    top: 20px;
}

.carousel_discount {
    position: absolute;
    top: 60px;
    left: 0px;
    height: 50px;
    width: 100%;
    text-align: center;
    /* font-family: var(--tile_font_family); */
    font-size: var(--sub_title_font_size);
    /* color: var(--selected_blue); */
}

.carousel_img {
    position: absolute;
    top: 110px;
    left: 20px;
    width: 178px;
    height: 178px;
    /* background-color: var(--light_blue); */
    object-fit: contain;
    /* border-radius: 25px; */
    border-radius: var(--medium_border_radius);
    padding: 20px;
    border: 1px solid var(--medium_gray);
}

.carousel_img_background {
    position: absolute;
    top: 110px;
    left: 20px;
    width: 220px;
    height: 220px;
    background-color: var(--light_blue);
    object-fit: cover;
    /* border-radius: 25px; */
    border-radius: var(--medium_border_radius);
}

.price_indicator {
    position: absolute;
    left: 70px;
    width: 120px;
    top: 305px;
    height: 50px;
    background-color: white;
    border: 1px solid var(--medium_gray);
    text-align: center;
    line-height: 55px;
    border-radius: 25px;
}


.inner_dollar_price {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 30px;
    line-height: 33px;
    /* font-family: var(--tile_font_family); */
    font-size: 25px;
}

.inner_apemax_qty {
    position: absolute;
    top: 30px;
    left: 0px;
    width: 100%;
    height: 20px;
    line-height: 12px;
    font-size: 14px;
}


.carousel_buttons {
    position: absolute;
    width: 100%;
    left: 0px;
    top: 360px;
}

.carousel_cover {
    position: absolute;
    width: 100%;
    left: 0px;
    top: 360px;
    height: 200px;
    display: none;
}

.carousel_button {
    position: relative;
    margin-left: 20px;
    width: calc(100% - 50px);
    height: 40px;
    text-align: center;
    line-height: 40px;
    /* border-radius: 50px; */
    border-radius: var(--medium_border_radius);
    background-color: var(--title_blue);
    border: 5px solid var(--outline_blue);
    /* background-color: var(--light_blue); */
    margin-top: 10px;
    cursor: pointer;
}

.carousel_button_extra {
    margin-top: 20px;
}

.carousel_button_deselected {
    line-height: 26px;
    border: 5px solid var(--light_gray);
    background-color: var(--mid_gray);
    pointer-events: none;
    opacity: 0.45;
}

.limited_offer_button {
    border: 5px solid transparent;
    background-color: transparent;
    font-size: 14px;
}

.carousel_button_cover {
    position: absolute;
    width: 100%;
    height: 60px;
    bottom: 0px;
}

.carousel_item_full {
    opacity: 1;
    transform: scale(1);
    transition: opacity 0.5s, transform 0.5s;
}

.carousel_item_medium {
    opacity: 0.5;
    transform: scale(0.9);
    transition: opacity 0.5s, transform 0.5s;
}

.carousel_item_small {
    opacity: 0.25;
    transform: scale(0.8);
    transition: opacity 0.5s, transform 0.5s;
}

@media (max-width: 767px) {
    .carousel_super_container {
        margin-left: -20px;
        width: calc(100% + 40px);
        /* background-color: red; */
    }

    .carousel_go_decrement  {
        left: 20px;
    }
    
    .carousel_go_increment  {
        right: 20px;
    }
}

@media (max-width: 380px) {

    .carousel_super_container {
        height: 617px;
    }

    .fade_carousel_left {
        display: none;
    }

    .fade_carousel_right {
        display: none;
    }

    .carousel_go_decrement  {
        top: auto;
        bottom: 5px;
        left: calc(50% - 60px);
    }
    
    .carousel_go_increment  {
        top: auto;
        bottom: 5px;
        right: calc(50% - 60px);
    }
}