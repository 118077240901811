
.counter_row {
    position: relative;
    width: 100%;
    height: 100px;
    /* background-color: blue; */
}

.counter_circle {
    position: absolute;
    left: calc(50% - 205px);
    top: 0px;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    background-color: white;
    border-radius: 80px;
    border: 3px solid var(--light_gray);
    font-family: var(--tile_font_family);
    font-size: var(--title_font_size);
}

.counter_circle_1 {
    left: calc(50% - 165px);
}

.counter_circle_2 {
    left: calc(50% - 125px);
}

.counter_item {
    position: absolute;
    left: calc(50% - 165px);
    top: 10px;
    height: 60px;
    width: 250px;
    padding-left: 60px;
    background-color: var(--light_gray);
    line-height: 60px;
    font-size: var(--sub_title_font_size);
    border-bottom-right-radius: 60px;
    border-top-right-radius: 60px;
}

.counter_item_1 {
    left: calc(50% - 125px);
}

.counter_item_2 {
    left: calc(50% - 85px);
}


@media (max-width: 767px) {
    .counter_circle {
        left: 0px;
    }

    .counter_circle_1 {
        left: 0px;
    }
    
    .counter_circle_2 {
        left: 0px;
    }

    .counter_item {
        left: 40px;
        width: calc(100% - 100px);
    }

    .counter_item_1 {
        left: 40px;
    }

    .counter_item_2 {
        left: 40px;
    }
}

@media (max-width: 500px) {
    .counter_circle {
        left: 0px;
        width: 60px;
        height: 60px;
        line-height: 60px;
        border-radius: 60px;
        font-size: 24px;
    }

    .counter_item {
        left: 50px;
        top: 7px;
        height: 50px;
        width: 250px;
        line-height: 50px;
        padding-left: 30px;
        width: calc(100% - 80px);
    }
}

@media (max-width: 350px) {
    .counter_item {
        font-size: 16px;
    }
}

@media (max-width: 325px) {
    .counter_item {
        font-size: 14px;
    }
}