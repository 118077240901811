.countdown_container {
    position: relative;
    padding: var(--small_margin);
    margin-top: 0px;
    margin-bottom: var(--small_margin);
    margin-left: calc(50% - 221px);
    width: 400px;
    border: 1px solid var(--medium_gray);
    border-radius: var(--medium_border_radius);
    box-shadow: var(--small_shadow);
}

.countdown_numbers_inner {
    margin-top: var(--medium_margin);
    width: 100%;
}

.countdown_number {
    display: inline-block;
    width: 20%;
    text-align: center;
    font-size: var(--title_font_size);
}

.countdown_description_inner {
    margin-top: var(--small_margin);
    width: 100%;
}

.countdown_descriptor {
    display: inline-block;
    width: 20%;
    text-align: center;
}

.countdown_seperator {
    display: inline-block;
    width: 6.66666%;
    text-align: center;
    font-size: var(--title_font_size);
}

.increase_time_bar_container {
    position: relative;
    margin-top: 40px;
    margin-left: calc(50% - 100px);
    width: 200px;
    height: 20px;
    border: 2px solid var(--medium_gray);
    border-radius: 18px;
}

.increase_time_bar_outer {
    position: absolute;
    top: 2px;
    left: 2px;
    height: 16px;
    width: 100%;
    overflow: hidden;
    border-radius: 18px;
}

/* .increase_time_bar_inner {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 16px;
    background-color: var(--outline_blue);
} */

.increase_time_bar_inner {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 16px;
    background: repeating-linear-gradient(
        -45deg,
        var(--outline_blue),
        var(--outline_blue) 10px,
        var(--title_blue) 10px,
        var(--title_blue) 20px
    );
    background-size: 28px 28px;
    animation: move_stripes 1s linear infinite;
}

@keyframes move_stripes {
    to { background-position: 28px 0; }
}



.alarm_clock_overlay_0 {
    position: absolute;
    width: 50px;
    height: 80px;
    object-fit: contain;
    bottom: 80px;
    left: -30px;
    transform: rotate(-15deg);
}

.alarm_clock_overlay_1 {
    position: absolute;
    width: 50px;
    height: 80px;
    object-fit: contain;
    bottom: 80px;
    right: -30px;
    transform: rotate(15deg);
}

@media (max-width: 767px) {
    .countdown_container {
        margin-left: 0px;
        width: calc(100% - 42px);
    }
}