

.news_container {
    position: relative;
    margin-top: 0px;
    margin-left: calc(50% - 400px);
    width: 800px;
    overflow: hidden;
}

.news_safe_inner {
    position: relative;
    display: block;
    width: calc(100% - 40px);
    padding: 20px;
    margin: 0px;
}

.banner_img {
    margin-top: 0px;
    width: 100%;
    display: block;
}

.banner_container {
    width: calc(100% - 2px);
    background-color: white;
    box-shadow: var(--small_shadow);
    border-radius: 25px;
    border: 1px solid var(--medium_gray);
}

.inline_full_button {
    display: inline-block;
    margin-bottom: 20px;
    margin-left: 20px;
    width: calc(50% - 40px);
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: var(--medium_border_radius);
    background-color: var(--title_blue);
    border: 5px solid var(--outline_blue);
    cursor: pointer;
    -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;
}

.indented_text {
    margin-left: 70px;
    width: calc(100% - 70px);
}

.left_icon {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 60px;
    height: 60px;
    object-fit: contain;
}

.news_header {
    position: relative;
    width: 100%;
    height: 50px;
    /* border-top: 1px solid var(--medium_gray); */
    border-bottom: 1px solid var(--medium_gray);
    /* margin-bottom: 20px; */
}

.news_icon {
    margin-top: 10px;
    margin-left: 10px;
    display: inline-block;
    top: 5px;
    left: 5px;
    height: 10px;
    background-color: red;
    border-radius: 10px;
    text-align: center;
    line-height: 10px;
    padding: 10px;
    color: white;
}

.social_icon {
    position: relative;
    margin-top: 5px;
    margin-right: 20px;
    display: inline-block;
    top: 5px;
    left: 5px;
    height: 10px;
    width: 30px;
    height: 30px;
    float: right;
    cursor: pointer;
}

.social_icon_inner {
    position: absolute;
    width: 100%;
    height: 100%;
}

.remove_lr_borders {
    width: calc(100% + 4px);
    margin-left: -2px;
}

.apemax_news_logo {
    width: 50%;
    margin-left: 25%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.secondary_button {
    border: 5px solid var(--light_gray);
    background-color: var(--mid_gray);
}

.other_articles_container {
    /* margin-top: 10px; */
    width: 100%;
    overflow: scroll;
    padding-bottom: 10px;
}

.other_articles_inner {
    width: 800px;
    overflow: hidden;
}

.other_article {
    display: block;
    width: 185px;
    border-radius: 20px;
}

.other_article_div {
    display: inline-block;
    width: 185px;
    margin-right: 20px;
    overflow: hidden;
}

.extra_line_spacer {
    margin-top: 8px;
    height: 1px;
    width: 100%;
    background-color: var(--medium_gray);
}

@media (max-width: 840px) {
    .banner_container {
        margin-left: 20px;
        width: calc(100% - 40px);
    }

    .other_articles_container {
        margin-left: 20px;
        width: calc(100% - 40px);
    }
}

@media (max-width: 800px) {
    .news_container {
        margin-left: 0px;
        width: 100%;
    }
}

@media (max-width: 640px) {
    .inline_full_button {
        width: calc(100% - 40px);
    }
}

@media (max-width: 470px) {

    .apemax_news_logo {
        margin-left: calc(50% - 110px);
        width: 220px;
    }

    .indented_above {
        position: relative;
        height: 60px;
        margin-bottom: 20px;
    }

    .indented_title {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }

    .indented_below {
        margin-left: -60px;
        width: calc(100% + 60px);
    }

}