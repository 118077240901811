
.live_sales_scrollable {
    width: 100%;
    height: 400px;
    overflow: scroll;
    
}

.live_sales_super {
    margin-top: 20px;
    position: relative;
}

.live_sale_item {
    position: relative;
    width: calc(100% - 2px);
    height: 90px;
    border: 1px solid var(--medium_gray);
    margin-top: 20px;
    /* border-radius: 25px; */
    border-radius: var(--medium_border_radius);
    /* background-color: var(--light_purple); */
    box-shadow: var(--small_shadow);
    /* box-shadow: var(--micro_shadow); */
}

.top_fade_white {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 20px;
    background: linear-gradient(to bottom, #fff, rgba(255, 255, 255, 0));
}

.bottom_fade_white {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 20px;
    background: linear-gradient(to top, #fff, rgba(255, 255, 255, 0));
}



.live_sales_img {
    position: absolute;
    left: 20px;
    top: 20px;
    width: 50px;
    height: 50px;
}

.live_sales_purchase_text {
    position: absolute;
    top: 20px;
    left: 90px;
    height: 25px;
    width: calc(50% - 30px);
    line-height: 25px;
}

.live_sales_time_text {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 25px;
    width: calc(50% - 80px);
    text-align: right;
    line-height: 25px;
}

.live_sales_price_text {
    position: absolute;
    top: 45px;
    left: 90px;
    height: 25px;
    width: calc(100% - 110px);
    line-height: 25px;
}


.live_sales_seperator_line {
    position: absolute;
    left: 20px;
    top: 70px;
    height: 1px;
    width: calc(100% - 40px);
    background-color: var(--charcol_gray);
}

@media (max-width: 340px) {
    .live_sales_time_text {
        font-size: 14px;
    }
    .live_sales_price_text {
        font-size: 12px;
    }
}
/* 
.dollar_bill_overlay_0 {
    position: absolute;
    bottom: 40px;
    left: -30px;
    width: 80px;
    height: 80px;
    object-fit: contain;
    transform: rotate(50deg);
    animation: 2s dollar_falling;
}

@keyframes dollar_falling {
    0% { top: 0% }
    100% { top: 100% }
}

.dollar_bill_overlay_1 {
    position: absolute;
    bottom: 40px;
    right: -30px;
    width: 80px;
    height: 80px;
    object-fit: contain;
} */