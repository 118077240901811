* {
    margin: 0;
    padding: 0;
    font-family: var(--main_font_family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html, body {
    height:100%;
    font-size: 18px;
}

input {
    font-size: 16px;
}

img {
    pointer-events: none;
}

.title_text {
    position: relative;
    margin-top: 80px;
    margin-bottom: var(--medium_margin);
    font-family: var(--tile_font_family);
    font-size: var(--title_font_size);
    /* width: 100%; */
    text-align: center;
    /* background-color: blue; */
}

.title_text_back {
    position: absolute;
    top: 2px;
    left: -2px;
    text-align: center;
    width: 100%;
    font-family: var(--tile_font_family);
}

.title_text_front {
    position: absolute;
    top: 0px;
    left: 0px;
    text-align: center;
    width: 100%;
    font-family: var(--tile_font_family);
    -webkit-text-stroke: 2px black;
    /* background-color: rgba(255, 0, 0, 0.25); */
}

.main_text {
    position: relative;
    /* position: absolute; */
    top: 0px;
    left: 0px;
    text-align: center;
    /* width: 100%; */
    font-family: var(--tile_font_family);
    -webkit-text-stroke: 2px rgba(0, 0, 0, 0);
    /* background-color: green; */
    color: rgba(0, 0, 0, 0);
}

.sizer_text {
    margin-top: 0px;
    margin-bottom: var(--medium_margin);
    font-family: var(--tile_font_family);
    font-size: var(--title_font_size);
    /* width: 100%; */
    opacity: 0;
}

.title_text_extra {
    margin-top: 130px;
}

.nerf_top_margin {
    margin-top: 20px;
}

.inner_title {
    width: 100%;
    margin-top: var(--small_margin);
    font-size: var(--sub_title_font_size);
    text-align: center;
}

.smaller_title {
    width: 100%;
    margin-top: var(--small_margin);
    text-align: center;
}

.full_width_button {
    position: relative;
    margin-top: 20px;
    margin-left: calc(50% - 155px);
    width: 300px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: var(--medium_border_radius);
    background-color: var(--title_blue);
    cursor: pointer;
    border: 5px solid var(--outline_blue);
    /* box-shadow: var(--micro_shadow); */
    -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;
}

.stake_button_gray {
    border: 5px solid var(--light_gray);
    background-color: var(--mid_gray);
}


.full_width_more_info {
    margin-top: 20px;
    margin-left: 40px;
    width: calc(100% - 80px);
    height: 50px;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
    -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;
}

.full_width_image {
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: calc(50% - 150px);
    width: 300px;
}

.smaller_width_image {
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: calc(50% - 120px);
    width: 240px;
}

.full_img_in_split {
    margin-top: 20px;
    margin-left: 20px;
    width: calc(100% - 20px);
}

.big_img {
    margin-top: 40px;
    margin-bottom: 20px;
    margin-left: 40px;
    width: calc(100% - 80px);
}

.medium_img {
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: calc(50% - 150px);
    width: 300px;
}

.mobile_only {
    display: none;
}

.desktop_only {
    display: block;
}

.extra_top_image {
    position: absolute;
    width: 200px;
    margin-left: calc(50% - 120px);
}

.extra_top_image_large {
    position: absolute;
    width: 300px;
    margin-left: calc(50% - 170px);
}
@media (max-width: 370px) {
    .extra_top_image_large {
        margin-left: 0px;
        width: calc(100% - 40px);
    }
}

.regular_extra_img {
    margin-top: 30px;
    margin-bottom: 20px;
    width: 200px;
    margin-left: calc(50% - 100px);
}

.all_cryptos_img {
    margin-top: 30px;
    width: 200px;
    margin-bottom: -10px;
    margin-left: calc(50% - 100px);
}

.extra_top_image_extra {
    margin-top: 40px;
}

.clickable_text {
    cursor: pointer;
}

@media (max-width: 767px) {

    .mobile_only {
        display: block;
    }

    .desktop_only {
        display: none;
    }

    .title_text {
        margin-top: 40px;
    }

    .title_text_extra {
        margin-top: 110px;
    }

    .extra_top_image {
        position: absolute;
        width: 140px;
        margin-left: calc(50% - 90px);
        margin-top: 20px;
    }

    .full_img_in_split {
        margin-left: 0px;
        width: 100%;
    }

}

@media (max-width: 500px) {
    .big_img {
        margin-top: 40px;
        margin-bottom: 0px;
        margin-left: 0px;
        width: 100%;
    }
}

@media (max-width: 380px) {
    .full_width_button {
        margin-left: calc(50% - 125px);
        width: 240px;
    }
}


/* EXTRA */
.no_spinner::-webkit-inner-spin-button,
.no_spinner::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.no_spinner {
    -moz-appearance: textfield;
}

.inline_sparkles_container_left {
    position: absolute;
    left: -35px;
    top: -5px;
    width: 30px;
    height: 30px;
    /* vertical-align: top; */
    /* background-color: red; */
}

.inline_sparkles_container_right {
    position: absolute;
    right: -35px;
    top: calc(100% - 20px);
    width: 30px;
    height: 30px;
    vertical-align: top;
    /* background-color: red; */
}

.sparkle_left_outline {
    display: block;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.sparkle_right_outline {
    display: block;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform: scaleX(-1);
}


.invisible {
    opacity: 0;
}