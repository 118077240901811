.component {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.main_header {
    position: relative;
    margin-left: calc(50% - 600px);
    width: 1200px;
    height: 100px;
}

.text_logo {
    position: absolute;
    top: 20px;
    left: 145px;
    font-family: var(--logo_font_size);
    /* font-size: var(--title_font_size); */
    font-size: 40px;
    line-height: 100px;
    cursor: pointer;
    -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;
}

.logo {
    position: absolute;
    top: 20px;
    left: 0px;
    width: 100px;
    height: 100px;
    object-fit: contain;
    cursor: pointer;
    pointer-events: auto;
    -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;
}

.footer_text_logo {
    position: absolute;
    left: 0px;
    font-family: var(--tile_font_family);
    font-size: var(--title_font_size);
    line-height: 100px;
    top: 150px;
    height: 50px;
}

.footer_logo {
    position: absolute;
    top: 20px;
    left: 0px;
    width: 120px;
}

.main_footer {
    position: relative;
    margin-left: calc(50% - 600px);
    width: 1200px;
    height: 250px;
}

.footer_push {
    height: 250px;
    width: 100%;
}

.disclaimer_container {
    margin-left: -20px;
    width: calc(100% + 40px);
    padding-bottom: 10px;
}

.footer_options_container {
    position: absolute;
    top: 130px;
    right: 0px;
    width: 600px;
    height: 110px;
    text-align: right;
}

.about_apemax_footer {
    display: inline-block;
    left: 0px;
    width: 25%;
    vertical-align: top;
}

.documents_footer {
    display: inline-block;
    left: 25%;
    width: 25%;
    vertical-align: top;
}

.terms_footer {
    display: inline-block;
    left: 50%;
    width: 25%;
    vertical-align: top;
}
.social_footer {
    display: inline-block;
    left: 75%;
    width: 25%;
    vertical-align: top;
}

.footer_small_title {
    font-family: var(--tile_font_family);
    font-size: var(--sub_title_font_size);
}

.credit_card_button_header {
    position: absolute;
    top: 40px;
    right: 0px;
    width: 210px;
    height: 50px;
    line-height: 50px;
    background-color: white;
    text-align: center;
    border-radius: var(--medium_border_radius);
    /* border-radius: var(--medium_border_radius); */
    cursor: pointer;
    -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;
}




.crypto_button_header {
    position: absolute;
    top: 40px;
    right: 230px;
    width: 210px;
    height: 50px;
    line-height: 50px;
    background-color: white;
    text-align: center;
    /* border-radius: 15px; */
    border-radius: var(--medium_border_radius);
    cursor: pointer;
    -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;
}

.button_footer {
    height: 40px;
    line-height: 40px;
    background-color: var(--title_blue);
    border: 5px solid var(--outline_blue);
    -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;
}

.language_selector {
    position: absolute;
    top: 40px;
    right: 0px;
    width: 90px;
    height: 40px;
    line-height: 40px;
    background-color: transparent;
    text-align: center;
    border-radius: var(--medium_border_radius);
    cursor: pointer;
    border: 5px solid white;
    -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;
}

.language_selector_footer {
    border: 5px solid var(--light_gray);
}

.language_drop_down {
    position: absolute;
    top: 100px;
    right: 0px;
    width: 80px;
    height: 200px;
    background-color: white;
    border-radius: var(--medium_border_radius);
    z-index: 10000;
    overflow: scroll;
    border: 10px solid white;
    box-shadow: var(--small_shadow);
    cursor: pointer;
    display: none;
}

.footer_language_drop_down {
    position: absolute;
    top: -200px;
    right: 0px;
    width: 80px;
    height: 200px;
    background-color: white;
    border-radius: var(--medium_border_radius);
    z-index: 10000;
    overflow: scroll;
    border: 10px solid white;
    box-shadow: var(--small_shadow);
    cursor: pointer;
    display: none;
}

.language_item {
    width: 100%;
    height: 40px;
    border-bottom: 1px solid var(--light_gray);
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;
}

.inner_component {
    position: relative;
    padding-top: var(--small_margin);
    padding-bottom: var(--small_margin);
    margin-left: calc(50% - 600px);
    width: 1200px;
    
}

.inner_component_with_overhang {
    padding-top: var(--extra_large_margin);
}

.inner_component_with_overhang_extra {
    padding-top: 160px;
}

.inner_component_with_overhang_extra_extra {
    padding-top: 180px;
}

.inner_component_sequential {
    margin-top: -80px;
}

.inner_component_sequential_extra {
    margin-top: -60px;
}

.img_block {
    position: relative;
    margin-top: var(--small_margin);
    margin-bottom: var(--small_margin);
    border-radius: var(--old_border_radius);
    width: calc(100% - var(--small_margin));
    border: var(--extra_small_margin) solid white;
    box-shadow: var(--small_shadow);
    overflow: hidden;
}

:root {
    --banner_desktop: url('');
    --banner_mobile: url('');
}

.inner_img_block {
    background-image: var(--banner_desktop);
    background-size: cover;
    width: 100%;
    padding-bottom: 56.25%;
    opacity: 0.25;
    transition: opacity 0.5s;
}

.inner_img_block_visible {
    opacity: 1;
    transition: opacity 0.25s;
}


.bread_crumb_container {
    position: absolute;
    width: 100%;
    height: 26px;
    bottom: 20px;
    text-align: center;
}

.bread_crumb {
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    width: 20px;
    height: 20px;
    border: 3px solid white;
    border-radius: 30px;
    cursor: pointer;
    -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;
}

.bread_crumb_selected {
    background-color: white;
}

.block_with_inner {
    margin-top: var(--small_margin);
    margin-bottom: var(--small_margin);
    border-radius: var(--old_border_radius);
    width: calc(100% - var(--small_margin));
    border: var(--extra_small_margin) solid white;
    /* background-image: url("../Assets/Images/rocket_background_orange.png"); */
    background-size: cover;
    box-shadow: var(--small_shadow);
}

.inner_block {
    position: relative;
    margin-top: var(--medium_margin);
    margin-bottom: var(--medium_margin);
    padding: var(--small_margin);
    margin-left: calc(50% - 320px);
    width: 600px;
    background-color: white;
    border-radius: var(--old_border_radius);
    box-shadow: var(--small_shadow);
}

.standard_block {
    margin-top: var(--small_margin);
    border-radius: var(--old_border_radius);
    background-color: white;
    box-shadow: var(--small_shadow);
}

.safe_inner {
    margin-top: var(--medium_margin);
    margin-bottom: var(--medium_margin);
    padding: var(--small_margin);
    margin-left: 40px;
    width: calc(100% - 120px);

}

.float_right {
    /* float: right; */
}

.inner_split_big {
    display: inline-block;
    width: calc(100% - 340px);
    vertical-align: middle;
}

.inner_split_small {
    display: inline-block;
    margin-left: 20px;
    width: 300px;
    vertical-align: middle;
}

.inner_half_split {
    display: inline-block;
    width: 50%;
    vertical-align: middle;
}

.overhang {
    position: absolute;
    left: calc(50% - 250px);
    width: 500px;
    top: 30px;
}

.underhang {
    position: relative;
    margin-top: -80px;
    margin-left: calc(50% - 100px);
    width: 200px;
}
/*
    In between sizes
*/
@media (max-width: 1240px) {
    .inner_component {
        margin-left: var(--small_margin);
        width: calc(100% - var(--medium_margin));
    }

    .main_header {
        margin-left: var(--small_margin);
        width: calc(100% - var(--medium_margin));
    }

    .main_footer {
        margin-left: var(--small_margin);
        width: calc(100% - var(--medium_margin));
    }
}

.credit_card_header {
    line-height: 35px;
    pointer-events: none;
    opacity: 0.45;
}




.credit_card_dissabled {
    line-height: 26px;
    border: 5px solid var(--light_gray);
    background-color: var(--mid_gray);
    pointer-events: none;
    opacity: 0.45;
    display: none;
}

.reg_dissabled {
    line-height: 50px;
    /* border: 5px solid var(--light_gray); */
    background-color: var(--mid_gray);
    pointer-events: none;
    opacity: 0.45;
    display: none;
}


.credit_card_dissabled_show {
    display: block;
}

.coming_soon_extra_text {
    position: absolute;
    bottom: 2px;
    width: 100%;
    text-align: center;
    font-size: 12px;
    height: 12px;
    line-height: 12px;
}

.coming_soon_extra_text_header {
    bottom: 7px;
}


/*
    Mobiles
*/
@media (max-width: 767px) {

    .inner_img_block {
        background-image: var(--banner_mobile);
        background-size: cover;
        width: 100%;
        padding-bottom: 126.430354%;
    }

    .inner_block {
        margin-top: -10px;
        margin-bottom: -10px;
        margin-left: -10px;
        width: calc(100% - 20px);
        box-shadow: none;
    }

    .inner_split_big {
        display: block;
        margin-left: 0px;
        width: 100%;
    }

    .inner_half_split {
        display: block;
        margin-left: 0px;
        width: 100%;
    }
    
    .inner_split_small {
        display: block;
        margin-left: 0px;
        width: 100%;
    }

    .overhang {
        position: absolute;
        top: 10px;
        left: calc(50% - 200px);
        width: 400px;
    }
    
    .safe_inner {
        margin-left: 0px;
        width: calc(100% - 40px);
        padding: 20px;
        padding-bottom: 20px;
    }

    .safe_inner_form {
        width: calc(100% - 40px);
        padding: 20px;
    }

    .inner_component_with_overhang {
        padding-top: 80px;
    }

    .inner_component_with_overhang_extra {
        padding-top: 120px;
    }

    .inner_component_with_overhang_extra_extra  {
        padding-top: 140px;
    }


    .main_header {
        height: 200px;
        margin-top: 50px;
    }

    .main_footer {
        height: 480px;
    }

    .footer_push {
        height: 480px;
        width: 100%;
    }

    .text_logo {
        width: 188px;
        height: 35px;
        line-height: 35px;
        left: calc(50% - 94px);
        top: -30px;
        /* background-color: red; */
        text-align: center;
    }

    .additional_banner {
        margin-top: 70px;
    }

    .logo {
        top: 100px;
        width: 120px;
        height: 120px;
    }


    .white_header {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 130px;
        background-color: white;
        box-shadow: var(--small_shadow);
        /* border-bottom: 1px solid black; */
    }

    .credit_card_button_header {
        top: 170px;
        right: 0px;
    }

    .crypto_button_header {
        top: 100px;
        right: 0px;
    }

    .crypto_footer {
        top: 30px;
    }

    .card_footer {
        top: 100px;
    }

    .language_selector {
        top: 160px;
        right: 0px;
    }

    .footer_options_container {
        top: 250px;
        right: 0px;
        width: 100%;
        height: 250px;
        text-align: left;
    }

    .about_apemax_footer {
        left: 0px;
        width: 50%;
    }
    
    .documents_footer {
        left: 50%;
        width: 50%;
    }
    
    .terms_footer {
        margin-top: 20px;
        left: 0%;
        width: 50%;
    }
    .social_footer {
        margin-top: 20px;
        left: 50%;
        width: 50%;
    }
}

@media (max-width: 500px) {

    .inner_component_with_overhang {
        padding-top: 80px;
    }

    .inner_component_with_overhang_extra {
        padding-top: 100px;
    }

    .inner_component_with_overhang_extra_extra  {
        padding-top: 140px;
    }

    .overhang {
        position: absolute;
        left: 40px;
        width: calc(100% - 80px);
    }
}

@media (max-width: 500px) {
    

    .inner_component_with_overhang {
        padding-top: 60px;
    }

    .inner_component_with_overhang_less {
        padding-top: 35px;
    }

    .inner_component_with_overhang_extra {
        padding-top: 90px;
    }

    .inner_component_with_overhang_extra_extra  {
        padding-top: 100px;
    }
}

@media (max-width: 420px) {
    .inner_component_with_overhang_extra {
        padding-top: 70px;
    }
}

@media (max-width: 380px) {

    .inner_component_with_overhang {
        padding-top: 40px;
    }

    .inner_component_with_overhang_extra {
        padding-top: 55px;
    }

    .inner_component_with_overhang_extra_extra  {
        padding-top: 80px;
    }

    .overhang {
        left: 20px;
        width: calc(100% - 40px);
    }

    .footer_logo {
        display: none;
    }

    .footer_text_logo {
        top: 0px;
        width: 100%;
        text-align: center;
    }

    .logo {
        display: none;
    }

    .credit_card_button_header {
        width: 100%;
    }

    .crypto_button_header {
        width: 100%;
    }

    .footer_options_container {
        text-align: center;
    }

    .crypto_footer {
        top: 100px;
    }
    
    .card_footer {
        top: 170px;
    }
    
}

