.info_container {
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
    width: calc(100% - 42px);
    height: 340px;
    border: 1px solid var(--medium_gray);
    background-color: white;
    box-shadow: var(--small_shadow);
    border-radius: 18px;
}

.info_container_large {
    height: 465px;
}

.info_image_icon {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 48px;
    height: 48px;
    border-radius: 50px;
    background-color: var(--medium_gray);
    border: 1px solid var(--charcol_gray);
}

.info_name {
    position: absolute;
    top: 20px;
    right: 20px;
    width: calc(100% - 110px);
    height: 50px;
    line-height: 30px;
    font-size: var(--sub_title_font_size);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.key_figures {
    position: absolute;
    top: 70px;
    left: 20px;
    width: calc(100% - 40px);

}

.key_figure_indicator {
    position: relative;
    display: inline-block;
    margin-top: 20px;
    margin-right: 20px;
    width: calc(50% - 12px);
    height: 100px;
    border: 1px solid var(--medium_gray);
    background-color: white;
    background-color: var(--light_gray);
    border-radius: 18px;
}

.key_figure_indicator_no_margin {
    margin-right: 0px;
}

.key_figure_amount {
    position: absolute;
    width: 100%;
    height: 50px;
    line-height: 50px;
    left: 0px;
    top: 10px;
    text-align: center;
    font-size: var(--sub_title_font_size);
}

.key_figures_amount_title {
    position: absolute;
    width: 100%;
    height: 20px;
    line-height: 20px;
    left: 0px;
    bottom: 25px;
    text-align: center;
}

.project_stake_container {
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
    width: calc(100% - 42px);
    height: 450px;
    border: 1px solid var(--medium_gray);
    background-color: white;
    box-shadow: var(--small_shadow);
    border-radius: 18px;
}

.creator_space_container {
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
    width: calc(100% - 42px);
    border: 1px solid var(--medium_gray);
    background-color: white;
    box-shadow: var(--small_shadow);
    border-radius: 18px;
    padding-bottom: 20px;
}

.project_stake_title {
    position: relative;
    top: 20px;
    left: 20px;
    width: calc(100% - 40px);
    height: 50px;
    line-height: 30px;
    font-size: var(--sub_title_font_size);
}

.refresh_button {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 30px;
    width: 30px;
    cursor: pointer;
    pointer-events: auto;
}


.disconnect_button {
    position: absolute;
    top: 0px;
    right: 50px;
    height: 30px;
    width: 30px;
    cursor: pointer;
    pointer-events: auto;
}

.spin_refresh {
    animation: infinite_spin 2s linear infinite;
}

.last_updated {
    position: absolute;
    top: 22.5px;
    font-size: 12px;
}

.stake_button {
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: calc(100% - 50px);
    height: 40px;
    line-height: 40px;
    background-color: var(--light_blue);
    text-align: center;
    background-color: var(--title_blue);
    border: 5px solid var(--outline_blue);
    border-radius: var(--medium_border_radius);
    cursor: pointer;
    -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;
}


.regular_button {
    margin-top: 20px;
    margin-left: 20px;
    width: calc(100% - 40px);
    height: 50px;
    line-height: 50px;
    background-color: var(--light_blue);
    text-align: center;
    border-radius: 18px;
    cursor: pointer;
    -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;
}

.stake_input_label {
    position: absolute;
    left: 20px;
    top: 80px;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
}

.regular_label {
    margin-left: 20px;
    margin-top: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
}

.stake_fees_preview {
    position: absolute;
    left: 20px;
    top: 180px;
    width: calc(100% - 40px);
    height: 20px;
    line-height: 20px;
    font-size: 14px;
}

.creator_claim_preview {
    position: relative;
    margin-left: 20px;
    margin-top: 20px;
    width: calc(100% - 40px);
    /* height: 20px; */
    line-height: 20px;
    font-size: 14px;
}

.fees_inner {
    position: relative;
    /* margin-left: 20px;
    width: calc(100% - 40px); */
}

.fees_value {
    position: absolute;
    top: 0px;
    right: 0px;
    text-align: right;
}

.line_sep {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 1px;
    background-color: var(--medium_gray);
}

.stake_input_amount {
    position: absolute;
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    top: 110px;
    left: 20px;
    width: calc(100% - 82px);
    height: 30px;
    line-height: 30px;
    border: 1px solid var(--medium_gray);
    /* border-radius: 50px; */
    border-radius: var(--medium_border_radius);
}

.royalties_input_amount {
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 20px;
    margin-left: 20px;
    width: calc(100% - 82px);
    height: 30px;
    line-height: 30px;
    border: 1px solid var(--medium_gray);
    border-radius: 18px;
}


.stake_input_input {
    position: absolute;
    right: 20px;
    top: 0px;
    height: 50px;
    width: calc(100% - 80px);
    text-align: right;
    border: none;
    background-color: transparent;
}

.stake_input_input:focus {
    outline: none;
}

.project_chart_container {
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
    width: calc(100% - 42px);
    height: 500px;
    border: 1px solid var(--medium_gray);
    background-color: white;
    box-shadow: var(--small_shadow);
    border-radius: 18px;
}

.pie_chart_container {
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
    width: calc(100% - 42px);
    height: 430px;
    border: 1px solid var(--medium_gray);
    background-color: white;
    box-shadow: var(--small_shadow);
    border-radius: 18px;
}

.project_activity_container {
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
    width: calc(100% - 42px);
    height: 400px;
    border: 1px solid var(--medium_gray);
    background-color: white;
    box-shadow: var(--small_shadow);
    border-radius: 18px;
    overflow: hidden;
}

.project_activity_inner_container {
    position: absolute;
    top: 90px;
    width: 100%;
    height: calc(100% - 90px);
    overflow: scroll;
    /* background-color: red; */
}

.activity_row {
    position: relative;
    width: 100%;
    height: 90px;
    border-top: 1px solid var(--light_gray);
}

.claimable_row {
    position: relative;
    width: 100%;
    height: 90px;
    border-top: 1px solid var(--light_gray);
}

.activity_icon {
    position: absolute;
    left: 20px;
    top: 20px;
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.activity_icon_rounded {
    position: absolute;
    left: 20px;
    top: 20px;
    width: 50px;
    height: 50px;
    object-fit: contain;
    /* border-radius: 50px; */
    border-radius: var(--medium_border_radius);
}

.activity_title {
    position: absolute;
    left: 90px;
    top: 20px;
    line-height: 30px;
}

.activity_sub_title {
    position: absolute;
    left: 90px;
    top: 50px;
    line-height: 20px;
    font-size: 14px;
}

.activity_time_label {
    position: absolute;
    top: 20px;
    right: 20px;
    line-height: 30px;
}

.activity_unstake_button {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 85px;
    height: 40px;
    background-color: var(--title_blue);
    border: 5px solid var(--outline_blue);
    text-align: center;
    line-height: 40px;
    /* border-radius: 25px; */
    border-radius: var(--medium_border_radius);
    cursor: pointer;
    -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;
}

.activity_claim_button {
    position: absolute;
    top: 20px;
    right: 120px;
    width: 75px;
    height: 40px;
    background-color: var(--title_blue);
    border: 5px solid var(--outline_blue);
    text-align: center;
    line-height: 40px;
    /* border-radius: 25px; */
    border-radius: var(--medium_border_radius);
    cursor: pointer;
    -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;
}

.rewards_chart {
    /* position: absolute; */
    margin-top: 20px;
    margin-left: 20px;
    width: calc(100% - 40px);
    height: calc(100% - 180px);
}

.pie_chart {
    margin-top: 30px;
    margin-left: 20px;
    width: calc(100% - 40px);
    height: calc(100% - 110px);
}

.rewards_chart canvas {
    height: 100%;
}

.timing_drop_down {
    position: relative;
    display: inline-block;
    /* right: 20px; */
    margin-top: 40px;
    margin-left: 20px;
    width: 140px;
    height: 50px;
    line-height: 50px;
    border: 1px solid var(--medium_gray);
    /* border-radius: 50px; */
    border-radius: var(--medium_border_radius);
    vertical-align: bottom;
    cursor: pointer;
    -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;
}

.timing_drop_down_list {
    position: absolute;
    top: 160px;
    left: 20px;
    height: 152px;
    width: 140px;
    border: 1px solid var(--medium_gray);
    /* border-radius: 25px; */
    border-radius: var(--medium_border_radius);
    background-color: white;
    z-index: 2;
    overflow: hidden;
    display: none;
    box-shadow: var(--small_shadow);
}


@media (max-width: 570px) {
    .claimable_row {
        height: 160px;
    }

    .activity_claim_button {
        top: 90px;
        left: 120px;
    }
    
    .activity_unstake_button {
        top: 90px;
        left: 20px;
    }

    .key_figures_amount_title {
        font-size: 14px;
    }
}

@media (max-width: 450px) {
    .key_figure_indicator {
        width: 100%;
        height: 80px;
    }

    .key_figure_amount {
        top: 0px;
    }
    
    .key_figures_amount_title {
        bottom: 15px;
        font-size: inherit;
    }

    .info_container {
        height: 510px;
    }

    .info_container_large {
        height: 720px;
    }
}

@media (max-width: 340px) {
    .key_figures_amount_title {
        font-size: 14px;
    }
}