
.ranking_container {
    margin-top: 30px;
    margin-bottom: 0px;
    padding: 20px;
    margin-left: 0px;
    width: 558px;
    overflow: scroll;
    border: 1px solid var(--medium_gray);
    border-radius: 25px;
    padding-top: 0px;
    box-shadow: var(--small_shadow);
    padding-bottom: 10px;
}



.description_ranking {
    display: inline-block;
    position: relative;
    width: 554px;
    height: 20px;
    line-height: 20px;
    margin-bottom: 25px;
    font-size: 14px;
    /* background-color: red; */
}

.ranking_top_10 {
    width: 554px;
}


.ranking_item {
    position: relative;
    width: 554px;
    height: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid var(--light_gray);
}

.ranking_counter {
    display: inline-block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    vertical-align: top;
    font-family: var(--tile_font_family);
    font-size: var(--title_font_size);
}

.ranking_counter_indicator {
    display: inline-block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    vertical-align: top;
}

.ranking_icon {
    display: inline-block;
    margin-left: 20px;
    width: 48px;
    height: 48px;
    border-radius: 50px;
    background-color: var(--light_gray);
    vertical-align: top;
    object-fit: cover;
    border: 1px solid var(--charcol_gray);
}

.ranking_contract_name {
    display: inline-block;
    margin-left: 20px;
    width: 160px;
    line-height: 50px;
    vertical-align: top;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ranking_amount_staked {
    display: inline-block;
    margin-left: 10px;
    width: 115px;
    line-height: 50px;
    vertical-align: top;
    text-align: center;
}

.contract_name_indicator {
    display: inline-block;
    margin-left: 20px;
    width: 230px;
    line-height: 50px;
    vertical-align: top;
}


@media (max-width: 767px) {
    .ranking_top_5 {
        display: block;
        width: 100%;
    }
    
    .ranking_top_10 {
        display: block;
        width: 100%;
    }

    .ranking_container {
        width: calc(100% - 40px);
    }
}