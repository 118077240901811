
.roadmap_img {
    position: absolute;
    top: 60px;
    left: 20px;
    width: 178px;
    height: 178px;
    /* background-color: var(--light_blue); */
    object-fit: contain;
    /* border-radius: 25px; */
    border-radius: var(--medium_border_radius);
    padding: 20px;
    border: 1px solid var(--medium_gray);
}

.features_img {
    position: absolute;
    top: 60px;
    left: 20px;
    width: 218px;
    height: 218px;
    /* background-color: var(--light_blue); */
    object-fit: contain;
    /* border-radius: 25px; */
    border-radius: var(--medium_border_radius);
    /* padding: 20px; */
    border: 1px solid var(--medium_gray);
}


.roadmap_img_background {
    position: absolute;
    top: 60px;
    left: 20px;
    width: 220px;
    height: 220px;
    background-color: var(--light_blue);
    object-fit: cover;
    /* border-radius: 25px; */
    border-radius: var(--medium_border_radius);
}

.roadmap_status_indicator {
    position: absolute;
    left: 70px;
    width: 120px;
    top: 263px;
    height: 30px;
    background-color: white;
    border: 1px solid var(--medium_gray);;
    text-align: center;
    line-height: 30px;
    border-radius: 25px;
}


.roadmap_list {
    position: absolute;
    width: 100%;
    left: 0px;
    top: 300px;
}

.roadmap_list_item {
    margin-left: 20px;
    width: calc(100% - 40px);
    height: 50px;
    text-align: center;
    line-height: 50px;
    /* border-radius: 50px; */
    border-radius: var(--medium_border_radius);
    background-color: var(--light_gray);
    margin-top: 10px;
}
