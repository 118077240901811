.form_container {
    width: 100%;
    margin-top: 40px;
}

.form_item_container {
    position: relative;
    display: inline-block;
    width: calc(50% - 10px);
    height: 100px;
    margin-right: 20px;
}

.form_item_container:nth-child(even) {
    margin-right: 0px;
}

.description_label {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-family: var(--tile_font_family);
}

.form_text_input {
    position: absolute;
    top: 30px;
    left: 0px;
    width: 100%;
    height: 50px;
    border: none;
    border-bottom: 3px solid var(--light_blue);
}

.form_text_input:focus {
    outline: none;
}

.form_tos_container {
    margin-left: 20px;
    margin-top: 20px;
    position: relative;
    width: calc(100% - 40px);
}

.form_tos_checkbox {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 30px;
    height: 30px;
    background-color: white;
    border: 1px solid var(--medium_gray);
    border-radius: 5px;
    cursor: pointer;
}

.tos_tick_icon {
    position: absolute;
    left: 5px;
    top: 5px;
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.form_tos_text {
    margin-left: 45px;
    padding-top: 0px;
    width: calc(100% - 45px);
    font-size: 12px;
}

.submit_form_button {
    margin-top: 40px;
    margin-left: 0px;
    width: 300px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: var(--medium_border_radius);
    /* background-color: var(--medium_blue); */
    background-color: var(--title_blue);
    border: 5px solid var(--outline_blue);
    /* box-shadow: var(--micro_shadow); */
    cursor: pointer;
    -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;
}

@media (max-width: 767px) {
    .form_item_container {
        width: 100%;
        margin-right: 0px;
    }

    .submit_form_button {
        width: calc(100% - 10px);
    }
}