.purchase_qty_container {
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
    width: calc(100% - 42px);
    border: 1px solid var(--medium_gray);
    background-color: white;
    box-shadow: var(--small_shadow);
    border-radius: 18px;
    padding-bottom: 20px;
    /* overflow: hidden; */
}

.purchase_error_container {
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
    width: calc(100% - 42px);
    border: 1px solid var(--dark_red);
    background-color: var(--v_light_red);
    box-shadow: var(--small_shadow);
    border-radius: 18px;
    padding-bottom: 20px;
}

.purchase_info_container {
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
    width: calc(100% - 42px);
    border: 1px solid var(--dark_blue);
    background-color: var(--light_blue);
    box-shadow: var(--small_shadow);
    border-radius: 18px;
    padding-bottom: 20px;
}

.unminted_apemax {
    border: 1px solid var(--medium_green);
    background-color: var(--vlight_green);
}

.purchase_stage_icon {
    position: relative;
    display: inline-block;
    margin-top: 20px;
    margin-left: 20px;
    width: 50px;
    height: 50px;
    vertical-align: middle;
    object-fit: contain;
}

.purchase_stage_icon_inner {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 50px;
    height: 50px;
}

.purchase_stage_icon_spin {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 50px;
    height: 50px;
    animation: infinite_spin 2s linear infinite;
}

@keyframes infinite_spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}



.purchase_stage_title {
    display: inline-block;
    margin-top: 30px;
    margin-left: 20px;
    width: calc(100% - 110px);
    height: 30px;
    /* line-height: 50px; */
    font-size: var(--sub_title_font_size);
    vertical-align: middle;
}

.usd_input_label {
    position: absolute;
    left: 20px;
    top: 180px;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
}

.usd_input_amount {
    position: absolute;
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    top: 210px;
    left: 20px;
    width: calc(100% - 82px);
    height: 30px;
    line-height: 30px;
    border: 1px solid var(--medium_gray);
    border-radius: 18px;
}

.button_purchase_funnel {
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
    width: calc(100% - 50px);
    height: 40px;
    line-height: 40px;
    /* text-align: center; */
    /* border-radius: 18px; */

    background-color: var(--title_blue);
    border: 5px solid var(--outline_blue);
    border-radius: var(--medium_border_radius);
    cursor: pointer;
    z-index: 1;
    -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.center_align {
    text-align: center;
}




.purchase_button_icon {
    position: relative;
    display: inline-block;
    margin-top: -5px;
    margin-left: 10px;
    margin-right: 10px;
    width: 30px;
    height: 30px;
    vertical-align: middle;
}

.purchase_button_icon_inner {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 30px;
    height: 30px;
}

.purchase_button_icon_spin {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 30px;
    height: 30px;
    animation: infinite_spin 2s linear infinite;
}

.discalimer_text {
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
    width: calc(100% - 42px);
    font-size: 12px;
    color: var(--charcol_gray);
}



.purchase_funnel_label {
    margin-left: 20px;
    margin-top: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
}

.purchase_funnel_extra_label {
    margin-left: 20px;
    margin-top: 20px;
    font-size: 14px;
    color: var(--dark_blue);
}

.purchase_funnel_input_container {
    position: relative;
    width: 100%;
}

.max_button {
    position: absolute;
    top: 30px;
    right: 192px;
    width: 50px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: var(--selected_gray);
    /* color: white; */
    /* border-radius: 30px; */
    border-radius: var(--medium_border_radius);
    font-size: 12px;
    cursor: pointer;
}

.purchase_funnel_input {
    display: inline-block;
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 20px;
    margin-left: 20px;
    width: calc(100% - 242px);
    height: 28px;
    line-height: 28px;
    border: 1px solid var(--medium_gray);
    /* border-radius: 50px; */
    border-radius: var(--medium_border_radius);
    vertical-align: bottom;
    margin-right: 20px;
}

.purchase_funnel_input::-webkit-outer-spin-button,
.purchase_funnel_input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.purchase_funnel_input[type='number'] {
    -moz-appearance: textfield;
}


.purchase_currency_drop_down {
    position: relative;
    display: inline-block;
    width: 140px;
    height: 50px;
    line-height: 50px;
    border: 1px solid var(--medium_gray);
    /* border-radius: 50px; */
    border-radius: var(--medium_border_radius);
    vertical-align: bottom;
    cursor: pointer;
    -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;
}



.lootbox_drop_down {
    position: relative;
    display: inline-block;
    width: calc(100% - 202px);
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    height: 50px;
    line-height: 50px;
    border: 1px solid var(--medium_gray);
    /* border-radius: 50px; */
    border-radius: var(--medium_border_radius);
    vertical-align: bottom;
    cursor: pointer;
    -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;
}



.purchase_background_drop_down_icon {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 30px;
    height: 30px;
    object-fit: cover;
    background-color: var(--light_blue);
}

.purchase_currency_drop_down_icon {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 30px;
    height: 30px;
    object-fit: contain;
}

.purchase_currency_drop_down_label {
    position: absolute;
    left: 50px;
    top: 10px;
    line-height: 30px;
}

.lootbox_drop_down_title {
    position: absolute;
    left: 50px;
    top: 5px;
    line-height: 20px;
}

.lootbox_drop_down_currency {
    position: absolute;
    left: 50px;
    top: 26px;
    line-height: 20px;
    font-size: 12px;
}

.purchase_currency_drop_down_extra {
    position: absolute;
    right: 15px;
    top: 10px;
    line-height: 30px;
    font-size: 12px;
}

.purchase_currency_drop_down_list {
    position: absolute;
    top: 200px;
    right: 18px;
    height: 305px;
    /* height: 101px; */
    /* height: 152px; */
    /* height: 203px; */
    width: 140px;
    border: 1px solid var(--medium_gray);
    /* border-radius: 25px; */
    border-radius: var(--medium_border_radius);
    background-color: white;
    z-index: 2;
    overflow: hidden;
    display: none;
    box-shadow: var(--small_shadow);
}

.purchase_lootbox_drop_down_list {
    position: absolute;
    top: 200px;
    left: 20px;
    height: 254px;
    width: calc(100% - 202px);
    border: 1px solid var(--medium_gray);
    /* border-radius: 25px; */
    border-radius: var(--medium_border_radius);
    background-color: white;
    z-index: 2;
    overflow: hidden;
    display: none;
    box-shadow: var(--small_shadow);
}

.purchase_currency_drop_down_item {
    position: relative;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid var(--medium_gray);
    cursor: pointer;
    -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;
}



.purchase_currency_descriptor {
    position: relative;
    display: inline-block;
    width: 140px;
    height: 50px;
    line-height: 50px;
    vertical-align: bottom;
    border: 1px solid var(--medium_gray);
    /* border-radius: 50px; */
    border-radius: var(--medium_border_radius);
}

.purchase_text {
    margin-left: 20px;
    margin-top: 20px;
    width: calc(100% - 40px);
    
}

.submit_email_button {
    position: relative;
    display: inline-block;
    width: 130px;
    height: 40px;
    /* line-height: 10px; */
    vertical-align: bottom;
    /* border-radius: 50px; */
    border-radius: var(--medium_border_radius);
    border: 5px solid var(--outline_blue);
    cursor: pointer;
    background-color: var(--title_blue);
    -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;
}



.submit_email_button_label {
    position: absolute;
    left: 0px;
    top: 0px;
    line-height: 40px;
    width: 100%;
    text-align: center;
    
}

.iframe_purchase {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    /* border: 1px solid red; */
}

.crossmint_logo {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 10px;
    vertical-align: bottom;
}

.explanation_text_cc {
    margin-top: 20px;
    margin-left: 20px;
    width: calc(100% - 40px);
    font-size: 14px;
    vertical-align: middle;
}


.mint_gas_type_container {
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
    width: calc(100% - 40px);
    /* height: 50px; */
    /* background-color: red; */
}

.gas_explanation_text {
    margin-top: 5px;
    width: 100%;
    font-size: 12px;
}

.green_text {
    color: var(--medium_green);
}

.red_text {
    color: var(--dark_red);
}

.gas_switch {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 35px;
    width: 60px;
    background-color: var(--medium_green);
    border-radius: 50px;
    transition: .4s;
    cursor: pointer;
}

.gas_switch_unselected {
    background-color: var(--medium_gray);
}

.gas_slider {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 25px;
    height: 25px;
    background-color: white;
    border-radius: 50px;
    transition: .4s;
}

.gas_slider_unselected {
    transform: translateX(-25px);
}

.standard_table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
}

.standard_table tr {
    border: 1px solid var(--medium_gray);
}

.standard_table td {
    padding: 10px;
    border: 1px solid var(--medium_gray);
    word-wrap: break-word;
    white-space: normal;
}


/* NEW PURCHASE FUNNEL */

.back_button {
    margin-left: 20px;
    margin-bottom: -20px;
    height: 50px;
    line-height: 50px;
    color: var(--selected_blue);
    cursor: pointer;
    display: inline-block;
}

.help_button {
    margin-right: 20px;
    margin-bottom: -20px;
    height: 50px;
    line-height: 50px;
    color: var(--selected_blue);
    cursor: pointer;
    float: right;
    display: inline-block;
}

@media (max-width: 500px) {

    .submit_email_button {
        margin-top: 20px;
        margin-left: 20px;
        width: calc(100% - 50px);
    }

    .email_input {
        width: calc(100% - 82px);
    }

    .purchase_funnel_input {
        width: calc(100% - 80px);
    }

    .max_button {
        right: 30px;
    }

    .purchase_currency_drop_down {
        margin-top: 20px;
        margin-left: 20px;
        width: calc(100% - 40px);
    }

    .purchase_currency_descriptor {
        margin-top: 20px;
        margin-left: 20px;
        width: calc(100% - 40px);
    }

    .purchase_currency_drop_down_list {
        top: 270px;
        width: calc(100% - 40px);
    }

    .lootbox_drop_down {
        width: calc(100% - 40px);
    }

    .purchase_lootbox_drop_down_list {
        width: calc(100% - 40px);
    }
}

@media (max-width: 358px) {
    .purchase_stage_title {
        margin-top: 5px;
    }
}